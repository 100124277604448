@tailwind base;
@tailwind components;
@tailwind utilities;

@import "./reset";
@import "./fonts";
@import "./variables";
@import "./breakpoints";
@import "./menu";
@import "./footer";
@import "./loader";
@import "./common";
@import "./promoBanner";
