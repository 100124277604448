//COMMON CSS
@keyframes heroOpacity {
    0% {
      opacity: 0.2;
    }
    50% {
      opacity: 0.2;
    }
    100% {
      opacity: 1;
    }
}

// BUTTON
button:disabled,
button[disabled] {
    background-color: rgb(var(--grey)) !important;
}

// AVIS VERIFIES
.skeepers_carousel_container {
    position: relative !important;

    .carousel__navigation {
        position: absolute !important;
        height: 15px;
        top: 14rem !important;
    }
}

//BANNER
.banner-main {

    .overlay {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background-color: rgb(var(--black));
        opacity: 0.4;
    }
}

// SALONS
.rooms {
    .half-title {
        span {
            font-size: 4rem;
        }
    }

    .room {
        &:hover > div {
            transform: scale(1.1);
            transition: all 0.3s ease-in-out;
        }
    }

}

//HERO
.hero {

    h1,
    .gold-trace,
    .content {
        animation: 2s ease-out 0s 1 heroOpacity;
    }

    @media screen and (min-width: $media-md) {
        a.text-white.user {
            position: relative;
            top: -4px;
        }
    }
}

//BRANDS
.brands {
    .quote * {
        font-family: var(--secondary);
        font-weight: var(--medium);
        line-height: 50px;
        color: rgb(var(--brown-light));
    }
    .brands-wrapper {
        width: 100%;
        overflow-x: scroll;

        @media screen and (min-width: $media-lg) {
            overflow: hidden;
        }

        &:has(.brand:nth-last-child(1):hover) {
            justify-content: end;
        }

        .brand {
            min-width: 80%;
            overflow: hidden;

            @media screen and (min-width: $media-lg) {
                min-width: calc(80% / 5);

                >figure {
                    min-width: 100%;
                    width: 100%;
                    img {
                        opacity: 1;
                        z-index: 0;
                        max-width: 100%;
                    }
                }

                &>div {
                    &.overlay {
                        opacity: 0 !important;
                    }
                }

                &:hover {
                    min-width: 400px;

                    &> figure {
                        min-width: 0;
                        width: 0;
                        img {
                            opacity: 0;
                            max-width: 0;
                        }
                    }

                    &>div {
                        opacity: 1;
                        z-index: 2;

                        &.overlay {
                            opacity: 0.5 !important;
                            z-index: 1;
                        }
                    }
                }

                &>div {
                    display: flex;
                    opacity: 0;
                    min-width: 400px;

                    p {
                        line-height: 1;
                    }

                }
            }

            &>div {
                opacity: 1;
                z-index: 2;

                &.overlay {
                    opacity: 0.5;
                    z-index: 1;
                }
            }
        }
    }
}

// OWL CAROUSEL
.owl-carousel {
    .owl-dots {
        position: absolute;
        top: -123px;
        flex-direction: revert;
        right: 0;
        display: flex;

        &.disabled {
            display: flex !important;
            justify-content: space-between;
        }

        .owl-dot{
            background: rgba(var(--black), 0.4);
            width: 7px;
            height: 7px;
            display: block;
            border-radius: 50%;
            margin-right: 10px;

            &.active {
                background: rgb(var(--rodolphe-blue));
                width: 7px;
                height: 7px;
                display: block;
                border-radius: 50%;
                margin-right: 10px;
            }
        }
    }

    .owl-nav {
        display: flex !important;
        justify-content: space-between;
        position: absolute;
        width: 100%;
        top: 50%;

        button {
            position: relative;
            margin-top: -40% !important;
            transform: translateY(50%);
        }

        .owl-prev {
            left: -85px;
        }

        .owl-next {
            left: 85px;
        }
    }

    .owl-item {
        border: 1px solid rgb(var(--grey-light));
        border-radius: 18px;
    }
}

@media screen and (max-width: $media-md) {
    body .owl-carousel .owl-nav,
    body .owl-carousel .owl-nav,
    body .owl-dots.disabled,
    body .owl-dots {
        display: none !important;
    }
}

// AUTRES CLASSES
.half-title {
    line-height: 1;

    span {
        font-size: var(--text-2xl);
        font-weight: var(--text-bold);
        line-height: 1;

        @media screen and (min-width: $media-lg) {
            line-height: 4rem;
        }
    }
}

.container {
    padding-inline: 1rem;

    @media screen and (min-width: $media-2xl) {
        max-width: 1480px;
    }

    @media screen and (max-width: $media-md) {
        max-width: 700px;
        margin: auto;
    }
}

.width-bottom-border:after {
    content: "";
    width: 125px;
    height: 1px;
    display: block;
    background: rgb(var(--rodolphe-blue));
    margin: 0 auto;
    margin-top: 11px;

}

.width-bottom-border.border-beige:after {
    background: rgb(var(--beige));
}

.img-cover {
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.icon-svg-white {
    svg {
        fill: rgb(var(--white));
    }
}

//TAXONS LIST
.taxons-list {

    .glide__arrow {
        display: flex !important;

        &.glide__arrow--left {
            svg {
                transform: rotate(-180deg);
            }
        }
    }
}

//FAVORITES PRODUCTS
.favorite-products {
    .shop-cta:hover {
        color: white !important;
        text-decoration: none;
    }

    .icon-shopping-bag {
        fill: white;
    }

    .products {
        ul {
            &::-webkit-scrollbar {
                display: none !important;
              }
        }
    }
}

//CARD PRODUCTS
.product-card {
    text-decoration: none !important;

    @media screen and (max-width: $media-md) {
        .form {
            height: 64px;
        }
    }

    @media screen and (min-width: $media-md) {
        &:hover {
            .form {
                height: 64px;
            }
        }
    }
}

// PRODUCT CATEGORIES
.products-categories >div {
    &::-webkit-scrollbar {
        display: none !important;
    }
}

//TOAST
.toastify {
    position: fixed;
    z-index: 999;
}

// FAQ
.faq {
    * {
        transition: all .3s ease-out;
    }

    .faq-title {
        span {
            display: block;
            line-height: 65px;
            font-size: var(--text-2xl);
            font-weight: 800;
        }
    }

    .faq-question {
        &:not(:first-child) {
            margin-top: 30px;
        }

        .btn {
            color: rgb(var(--black));
            background-color: rgb(var(--cream));

            svg {
                width: 22px;
                height: 22px;
            }
        }

        .minus-icon {
            opacity: 0;
        }

        .plus-icon {
            opacity: 1;
        }

        input:checked~.faq-answer {
            margin-top: 16px;
            margin-bottom: 24px;
            max-height: 50rem;
        }

        input:checked~label .minus-icon {
            opacity: 1
        }

        input:checked~label .plus-icon {
            opacity: 0
        }

        &:hover {
            .question-label {
                margin-left: 20px;
            }

            .btn {
                color: rgb(var(--white));
                background-color: rgb(var(--black));
            }
        }
    }
}


// MARQUES
section.temoignage img {
    object-fit: contain;
}

section#_rainbow_gamme_produits .owl-item.active:nth-child(2n+3) .form.relative.bg-rodolphe-blue {
    background: #FDD685;
}

.owl-carousel.team-partenaire.wow.fadeIn.owl-loaded.owl-drag .owl-item,
.owl-carousel.certifications-carousel.wow.fadeIn.owl-loaded.owl-drag .owl-item,
.team-galerie_thumbails .owl-item {
    border: none !important;
}

@media screen and (min-width:700px) and (max-width:1024px) {
    ._rainbow_nuance_coloration  {
        float: left;
        width: 20%;
    }
}

@media screen and (max-width: 1024px) {

    section#_rainbow_nuance_coloration {
        background: none !important;
    }

    .absolute_Responsive {
        position: absolute;
        right: 0;
        left: 0;
        margin: 0 auto;
        z-index: 0;
        opacity: 0.2;
        margin-top: 45px;
        width: 110px !important;
    }

    section#_single_produits {
        background-size: contain !important;
    }

    section#_couverture_optimale {
        background-image: none !important;
    }

    #_histoire_ingredient {
        display: block;
        margin-block: 14px;

    }

    img.hide-resp {
        display: none;
    }
}

@media screen and (max-width: 650px) {
    img.hide-table {
        display: block;
    }

    #_histoire_ingredient {
        display: block;
    }

    ._rainbow_nuance_coloration  .text-center.font-medium.mt-3 {
        font-size: 14px !important;
    }

    ._rainbow_nuance_coloration  {
        float: left;
        width: 33.33%;
    }

    .img_nofloat {
        width: 100% !important;
    }
}

.greenleaf-produit-blanc .owl-dots.disabled button.owl-dot.active,
.greenleaf-produit-blanc .owl-dots button.owl-dot.active {
    background: rgb(var(--white));
}

.greenleaf-produit-blanc .form.relative.bg-rodolphe-blue {
    background: #BED5DF;
}

.owl-carousel.team-partenaire.wow.fadeIn.owl-loaded.owl-drag .owl-nav,
.team-galerie_thumbails .owl-nav {
    display: none !important;
}

.slide-thumbail {
    text-align: center;
}

.slide-thumbail .rounded-full {
    margin: 0 auto;
    margin-bottom: 12px;
}

@media screen and (min-width: 1500px) {
    body .max-width-container {
        max-width: 1400px !important;
        margin: 0 auto !important;
    }
}

.team-partenaire img {
    object-fit: contain !important;
}

// SHARE
.btn_wrap {
    margin-left: 25px;
    transition: all .2s ease-in-out;

    &:hover {
        /* transition-delay: .4s; */
        -webkit-transform: scale(1.1);
        transform: scale(1.1);
        width: 180px;

        span {
            -webkit-transition-delay: .02s;
            transition-delay: .02s;
            -webkit-transform: translateX(-70px);
            transform: translateX(-70px)
        }

        .social-media {
            opacity: 1;
            -webkit-transform: scale(1);
            transform: scale(1);
        }
    }

    .social-media {
        opacity: 0;
        will-change: transform;
        -webkit-transform: scale(.1);
        transform: scale(.1);
        -webkit-transition: all .25s ease;
        transition: all .25s ease;

        &:nth-of-type(1) {
            -webkit-transition-delay: .7s;
            transition-delay: .7s;
        }

        &:nth-of-type(2) {
            -webkit-transition-delay: .5s;
            transition-delay: .5s;
        }

        &:nth-of-type(3) {
            -webkit-transition-delay: .3s;
            transition-delay: .3s;
        }
    }

    span {
        z-index: 99;
        top: -0.2rem;
        left: -4px;
        -webkit-transition: all 0.3s ease;
        transition: all 0.3s ease;
        padding: 15px;
    }
}


.training-page {
    .description-title {
        span {
            color: rgb(var(--beige));
            display: block;
            font-size: 4.25rem;
            margin-top: 10px;
            line-height: 1;

            @media screen and (min-width: $media-lg) {
                line-height: 1.2;
            }

        }
    }

    .registration-details ul {
        list-style: disc;
    }

    .price-details {
        span {
            font-weight: 800;
            font-size: var(--text-2xl);
            display: block;
        }
    }
}


.bg_lazy {
    background-image: none !important;
}

.text-relative-background {
    background: inherit;
    background-clip: text;
    color: transparent;
    filter: invert(1) grayscale(100%) contrast(10000%);
}

.logos-wrapper {
    &::-webkit-scrollbar {
        display: none !important;
      }
}

.form-options > div {
    flex-wrap: wrap;
    justify-content: end;
}

.faq {
    .intro p {
        background: linear-gradient(
            to right,
            rgba(var(--brown-light), 1) 50%,
            rgba(var(--brown-light), 0.2) 50%,
          );
          font-family: var(--secondary);
          background-size: 200% 100%;
          background-position-x: 100%;
          color: transparent;
          background-clip: text;
          -webkit-background-clip: text;
    }
}

// ARTICLE COMPONENET
.news-preview {
    .posts {
        &>article:last-child {
            display: none;
            @media screen and (min-width: $media-lg) {
                display: block;
            }
        }
    }
}

.post-container {
    .post {
        .content {
            a {
                color: rgb(var(--greenleaf-pink));
                font-weight: bold;
                text-decoration: none;

                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }
}

.menu_resp {
    a, span {
        color: rgb(var(--white));
    }
}

.menu_resp.dark-text {
    a, span {
        color: rgb(var(--black));
    }
}

.menu_resp.light-text {
    a, span {
        color: rgb(var(--white));
    }
}

label.required:not(.label-not-required)::after {
    content: "*";
    display: inline-block;
    padding-left: 4px;
    color: #dc3545;
}

.old-price {
    text-decoration: line-through;
}