body {
    
    *:not(i), .ui, .ui.header {
        font-family: var(--primary);
    }
    
    * {
        transition: all 1s cubic-bezier(0.25, 0.8, 0.25, 1);
    }

    p {
        margin: 0;
    }

    a {
        &:not(.btn) {
            &:hover {
                text-decoration: underline;
                color: inherit !important;
            }
        }
    }

    h1,h2,h3,h4,h5,h6 {
        line-height: 1;
    }

    .container {
        margin: auto;
    }

    .fluid-right {
        padding-inline: 1rem;
        overflow: hidden;

        @media screen and (min-width: 976px) {
            padding-right: 0;
            padding-left: 3rem;
            margin-right: 0;
        }

        @media screen and (min-width: 1440px) {
            padding-right: 0;
            padding-left: 6rem;
            margin-right: 0;
        }

        @media screen and (min-width: 1900px) {
            padding-right: 0;
            padding-left: 12.5rem;
            margin-right: 0;
        }

        @media screen and (min-width: 2000px) {
            padding-right: 0;
            padding-left: 15rem;
            margin-right: 0;
        }
    }

    .fluid-left {
        padding-inline: 1rem;
        overflow: hidden;

        @media screen and (min-width: 976px) {
            padding-left: 0;
            padding-right: 3rem;
            margin-left: 0;
        }

        @media screen and (min-width: 1440px) {
            padding-left: 0;
            padding-right: 6rem;
            margin-left: 0;
        }

        @media screen and (min-width: 1900px) {
            padding-left: 0;
            padding-right: 12.5rem;
            margin-left: 0;
        }

        @media screen and (min-width: 2000px) {
            padding-left: 0;
            padding-right: 15rem;
            margin-left: 0;
        }
    }
}

html {
    scroll-behavior: smooth;
}

//RADIOS
input[type="radio"]:checked, input[type="checkbox"]:checked {
    background-color: rgb(var(--brown-light)) !important;
}
