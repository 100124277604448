.promo-banner {
    transition: height $promoBannerAnimationSpeed linear;
    height: $promoBannerHeight;
    overflow: hidden;
}

.promo-banner-text {
    height: 100%;

    p {
        display: inline;
        font-size: .9rem;
        font-weight: 500;
        margin: 0;
        line-height: $promoBannerHeight;
        visibility: hidden;

        @media screen and (min-width: 425px) {
            font-size: 1.3rem;
        } 
    }
}

.promo-banner-hidden {
    height: 0px;
    transition: height $promoBannerAnimationSpeed linear;
}
