@layer base {
  :root {
    //COULEURS
    --black: 0, 0, 0;
    --black-light: 17, 16, 16;
    --white: 255, 255, 255;
    --grey: 157, 157, 156;
    --grey-medium: 215, 215, 215;
    --grey-light: 244, 244, 244;
    --cream: 251, 245, 242;
    --brown-light: 194, 156, 134;
    --greenleaf-pink: 251, 198, 197;
    --greenleaf-pink-dark: 210, 143, 144;
    --rodolphe-blue: 39, 61, 84;
    --rainbow-blue: 148, 198, 218;
    --rainbow-yellow: 253, 214, 133;
    --rainbow-purple: 174, 151, 199;
    --rainbow-green: 157, 196, 152;
    --rainbow-red: 233, 72, 96;
    --rainbow-orange: 243, 155, 120;
    --rainbow-orange-dark: 243, 155, 120;
    --rainbow-pink: 219, 146, 190;
    --calypso-gold: 214, 173, 96;
    --beige: 221, 174, 123;
    --coeurl-cream: 217, 184, 141;
    --coeurl-beige: 207, 158, 93

    //FONT FAMILY
    --primary: 'Inter';
    --secondary: 'Playfair Display';

    //FONT SIZE
    --text-xs: 1rem;
    --text-sm: 1.125rem;
    --text-md: 1.375rem;
    --text-lg: 1.875rem;
    --text-xl: 2.625rem;
    --text-xxl: 3.625rem;
    --text-2xl: 4.25rem;
    --text-2xxl: 4.625rem;
    --text-3xl: 6.625rem;

    //FONT WEIGHT
    --text-light: 400;
    --text-medium: 500;
    --text-semi-bold: 600;
    --text-bold: 700;
    --text-black: 900;

  }

}

$promoBannerHeight: 30px;
$promoBannerAnimationSpeed: .15s;
