@font-face {
    font-family: 'Inter';
    src: url('../fonts/Inter/Inter-Thin.woff2') format('woff2'),
        url('../fonts/Inter/Inter-Thin.woff') format('woff'),
        url('../fonts/Inter/Inter-Thin.ttf') format('truetype');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Inter';
    src: url('../fonts/Inter/Inter-Regular.woff2') format('woff2'),
        url('../fonts/Inter/Inter-Regular.woff') format('woff'),
        url('../fonts/Inter/Inter-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Inter';
    src: url('../fonts/Inter/Inter-SemiBold.woff2') format('woff2'),
        url('../fonts/Inter/Inter-SemiBold.woff') format('woff'),
        url('../fonts/Inter/Inter-SemiBold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Inter';
    src: url('../fonts/Inter/Inter-Black.woff2') format('woff2'),
        url('../fonts/Inter/Inter-Black.woff') format('woff'),
        url('../fonts/Inter/Inter-Black.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Inter';
    src: url('../fonts/Inter/Inter-Medium.woff2') format('woff2'),
        url('../fonts/Inter/Inter-Medium.woff') format('woff'),
        url('../fonts/Inter/Inter-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Inter';
    src: url('../fonts/Inter/Inter-ExtraLight.woff2') format('woff2'),
        url('../fonts/Inter/Inter-ExtraLight.woff') format('woff'),
        url('../fonts/Inter/Inter-ExtraLight.ttf') format('truetype');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Inter';
    src: url('../fonts/Inter/Inter-Light.woff2') format('woff2'),
        url('../fonts/Inter/Inter-Light.woff') format('woff'),
        url('../fonts/Inter/Inter-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Inter';
    src: url('../fonts/Inter/Inter-ExtraBold.woff2') format('woff2'),
        url('../fonts/Inter/Inter-ExtraBold.woff') format('woff'),
        url('../fonts/Inter/Inter-ExtraBold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Inter';
    src: url('../fonts/Inter/Inter-Bold.woff2') format('woff2'),
        url('../fonts/Inter/Inter-Bold.woff') format('woff'),
        url('../fonts/Inter/Inter-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

//PLAYFAIR DIS
@font-face {
    font-family: 'Playfair Display';
    src: url('../fonts/PlayFairDisplay/PlayfairDisplay-Regular.woff2') format('woff2'),
        url('../fonts/PlayFairDisplay/PlayfairDisplay-Regular.woff') format('woff'),
        url('../fonts/PlayFairDisplay/PlayfairDisplay-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Playfair Display';
    src: url('../fonts/PlayFairDisplay/PlayfairDisplay-ExtraBoldItalic.woff2') format('woff2'),
        url('../fonts/PlayFairDisplay/PlayfairDisplay-ExtraBoldItalic.woff') format('woff'),
        url('../fonts/PlayFairDisplay/PlayfairDisplay-ExtraBoldItalic.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Playfair Display';
    src: url('../fonts/PlayFairDisplay/PlayfairDisplay-Bold.woff2') format('woff2'),
        url('../fonts/PlayFairDisplay/PlayfairDisplay-Bold.woff') format('woff'),
        url('../fonts/PlayFairDisplay/PlayfairDisplay-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Playfair Display';
    src: url('../fonts/PlayFairDisplay/PlayfairDisplay-BlackItalic.woff2') format('woff2'),
        url('../fonts/PlayFairDisplay/PlayfairDisplay-BlackItalic.woff') format('woff'),
        url('../fonts/PlayFairDisplay/PlayfairDisplay-BlackItalic.ttf') format('truetype');
    font-weight: 900;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Playfair Display';
    src: url('../fonts/PlayFairDisplay/PlayfairDisplay-Italic.woff2') format('woff2'),
        url('../fonts/PlayFairDisplay/PlayfairDisplay-Italic.woff') format('woff'),
        url('../fonts/PlayFairDisplay/PlayfairDisplay-Italic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Playfair Display';
    src: url('../fonts/PlayFairDisplay/PlayfairDisplay-ExtraBold.woff2') format('woff2'),
        url('../fonts/PlayFairDisplay/PlayfairDisplay-ExtraBold.woff') format('woff'),
        url('../fonts/PlayFairDisplay/PlayfairDisplay-ExtraBold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Playfair Display';
    src: url('../fonts/PlayFairDisplay/PlayfairDisplay-SemiBoldItalic.woff2') format('woff2'),
        url('../fonts/PlayFairDisplay/PlayfairDisplay-SemiBoldItalic.woff') format('woff'),
        url('../fonts/PlayFairDisplay/PlayfairDisplay-SemiBoldItalic.ttf') format('truetype');
    font-weight: 600;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Playfair Display';
    src: url('../fonts/PlayFairDisplay/PlayfairDisplay-MediumItalic.woff2') format('woff2'),
        url('../fonts/PlayFairDisplay/PlayfairDisplay-MediumItalic.woff') format('woff'),
        url('../fonts/PlayFairDisplay/PlayfairDisplay-MediumItalic.ttf') format('truetype');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Playfair Display';
    src: url('../fonts/PlayFairDisplay/PlayfairDisplay-Black.woff2') format('woff2'),
        url('../fonts/PlayFairDisplay/PlayfairDisplay-Black.woff') format('woff'),
        url('../fonts/PlayFairDisplay/PlayfairDisplay-Black.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Playfair Display';
    src: url('../fonts/PlayFairDisplay/PlayfairDisplay-BoldItalic.woff2') format('woff2'),
        url('../fonts/PlayFairDisplay/PlayfairDisplay-BoldItalic.woff') format('woff'),
        url('../fonts/PlayFairDisplay/PlayfairDisplay-BoldItalic.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Playfair Display';
    src: url('../fonts/PlayFairDisplay/PlayfairDisplay-SemiBold.woff2') format('woff2'),
        url('../fonts/PlayFairDisplay/PlayfairDisplay-SemiBold.woff') format('woff'),
        url('../fonts/PlayFairDisplay/PlayfairDisplay-SemiBold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Playfair Display';
    src: url('../fonts/PlayFairDisplay/PlayfairDisplay-Medium.woff2') format('woff2'),
        url('../fonts/PlayFairDisplay/PlayfairDisplay-Medium.woff') format('woff'),
        url('../fonts/PlayFairDisplay/PlayfairDisplay-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}
