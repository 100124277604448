footer {
    padding-top: calc(3.5rem + 56px);
    padding-bottom: calc(2.5rem);
    z-index: 90;

  .decoration-border {
    border-bottom-right-radius: 2.5rem;
    border-bottom-left-radius: 2.5rem;
  }

  .newsletter-form {
    position: relative;
    min-width: 280px;
    width: 100%;

    input {
      width: 100%;

      &::placeholder {
        color: var(--grey);
      }

      &:focus {
        outline: none;
        border-color: var(--white) !important;

        & + button  {
          padding-inline: 0.8rem !important;
        }
      }
    }

    button {
      position: absolute;
      right: 0;
      border: 1px solid var(--white);

    }
  }

  .list-disc li:not(:first-of-type)::marker {
    content: "\2022  ";
  }
}
