@mixin switch {
    .switch {
        position: relative;
        display: inline-block;
        width: 2rem;
        height: 1rem;
        margin-top: 0 !important;

        input {
            opacity: 0;
            width: 0;
            height: 0;

            &:checked {

                &+.slider {
                    background-color: rgb(var(--black));
                    border: solid 1px rgb(var(--black));

                    &:before {
                        -webkit-transform: translateX(1rem);
                        -ms-transform: translateX(1rem);
                        transform: translateX(1rem);
                    }
                }
            }

            &:focus+.slider {
                box-shadow: 0 0 1px rgb(var(--black));
            }
        }

        .slider {
            position: absolute;
            cursor: pointer;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: rgb(var(--black));
            border: 1px solid #ccc;
            -webkit-transition: .4s;
            transition: .4s;

            &:before {
                position: absolute;
                content: "";
                height: 0.8rem;
                width: 0.8rem;
                left: 0;
                bottom: 0;
                background-color: rgb(var(--white));
                -webkit-transition: .4s;
                transition: .4s;
            }

            &.round {
                border-radius: 2rem;

                &:before {
                    border-radius: 50%;
                }
            }
        }
    }
}