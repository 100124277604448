@import "./switch";

header {
    .socials {
        li {
            min-width: 2rem;
            min-height: 2rem;

            a {
                &:hover {
                    svg {
                        color: rgb(var(--white)) !important;
                        transform: scale(1.3);
                    }
                }

                svg {
                    max-height: 0.8rem;
                }
            }
        }
    }

    // SWITCHER
    .switcher {
        display: none;

        &.locale-switcher {
            display: flex;
        }

        @media screen and (min-width: $media-lg) {
            display: flex;
        }

        p {
            color: rgb(var(--black));
        }

        @include switch;
    }

    .menu {
        .nav {
            a.menux {
                &:hover {
                    color: rgb(var(--white)) !important;
                }
            }

            li > ul {
                transform: translatex(100%) scale(0)
            }

            li:hover > ul {
                transform: translatex(101%) scale(1)
            }

            li > button svg {
                transform: rotate(-90deg)
            }

            li:hover > button svg {
                transform: rotate(-270deg)
            }

            .group:hover .group-hover\:scale-100 {
                transform: scale(1)
            }

            .group:hover .group-hover\:-rotate-180 {
                transform: rotate(180deg)
            }

            .scale-0 {
                transform: scale(0)
            }

        }

        .burger {
            min-width: 2rem;
            min-height: 2rem;
            cursor: pointer;

            & svg {
                max-height: 1rem;
            }
        }

        .user {
            &:hover {
                color: rgb(var(--white)) !important;
                transform: scale(1.3);
            }
        }

        .cart {
            &:hover {
                text-decoration: underline;
                cursor: pointer;
            }
        }

        .counter {
            min-width: 2rem;
            min-height: 2rem;
        }

        &.hamburger {
            z-index: 100;
            min-width: 320px;
            transform: translateX(-100%);

            .sub-sub-menu {
                display: none;
            }

            .sub-menu {
                li:hover .sub-sub-menu {
                    display: block;
                }
            }

            ul li {
                border-color: rgb(var(--white)) !important;
            }

            .nav li > ul {
                transform: none;
            }

            .switcher {
                p {
                    color: rgb(var(--white)) !important;
                }
            }

            input {
                &:checked {
                    & + .slider {
                        background-color: rgb(var(--black));
                        border: 1px solid rgb(var(--white));
                    }
                }
            }
        }
    }
}
